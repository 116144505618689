import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0027 = () => (
  <Wrapper>
    <ColumnH1 label="残業とは何か？残業の定義や上限時間などを解説します" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        仕事には、残業がつきものといっても過言ではないでしょう。しかし、残業にまつわるさまざまな労使トラブルは後を絶たず、深刻な社会問題化していることも現状です。
        <br />
        経営者には、社員にどこまでの時間まで残業をさせても良いのか、残業をさせた場合の賃金支払いはどのようになっているのか、などの残業にまつわる基本的なルールを熟知することが必須要件として求められています。
        <br />
        今回は、残業に関する基本的なルールについて、順を追って解説をしていきましょう。
      </p>
      <img src="/images/column/details/c0027.jpg" alt="キンクラコラム画像27" />
      <h1>「残業」とは何か</h1>
      <p>
        残業とは、社員が会社で決められた就労時間を超えて働くことです。同じような意味の言葉として
        <span>「時間外労働」</span>
        が挙げられますが、残業と時間外労働は意味合いが異なる点に注意をしなければなりません。
        <br />
        <br />
        残業は、先ほども述べたように、会社でルールづけられた就労時間、つまり
        <span>「所定労働時間」を超えて働いた場合の時間</span>
        をいいます。一方で、時間外労働とは、労働基準法という法律で定められた労働時間の上限となる
        <span>「法定労働時間」を超えて働いた場合の時間</span>を指します。
        <br />
        <br />
        法定労働時間は、一日あたり8時間まで、一週間あたり40時間までの時間を上限として定めています。
        <br />
        この法定労働時間と会社で定める所定労働時間を同じ時間で設定している会社の場合は、「残業＝時間外労働」という扱いとなりますが、所定労働時間を法定労働時間より少ない時間帯で定めている会社の場合は、残業と時間外労働の定義が若干異なります。
        <br />
        <br />
        たとえば、9時から17時を就労時間とし、うち12時から13時までの1時間を休憩時間としている会社の場合、就労時間は7時間となります。この場合は、終業時刻である17時を超えて働いた場合は「残業」扱いとなり、18時を超えて働いた場合は「残業」扱いとなる点に加え、「時間外労働」扱いにもなります。
      </p>
      <h1>時間単位の残業時間</h1>
      <p>
        ここからは、時間単位ごとの残業について順に説明をしていきます。
        <br />
        まず、一日・一週間あたりの残業時間についてです。先ほども述べたように、
        <span>
          一日あたりの法定労働時間は8時間、一週間あたりの法定労働時間は40時間が原則
        </span>
        です。この時間を超えて働かせる場合は、あらかじめ労使間で36協定を締結し、労働基準監督署へ届け出る必要があります。
      </p>
      <Introduction003 />
      <h1>残業時間の上限とは</h1>
      <p>
        ここで覚えておかなければならないのが、法改正により、時間外労働の上限規制が新たに設けられたという点です。この上限規制に違反をした場合は、6ヶ月の懲役または30万円以下の罰金に処せられる可能性があります。
        <br />
        <br />
        具体的な上限時間は、<span>1ヶ月あたり45時間、一年あたり360時間</span>
        となり、よほどの臨時的な事情がない限りは、この上限時間を超えてはらなないとされています。この上限時間を按分すると、一日あたり2時間程度の残業が目安となるでしょう。
        <br />
        <br />
        今回の法改正は、依然として深刻な問題として取りざたされている過重労働問題に対応するために施行されたものであるため、社員の残業時間の規制が厳しく取り決められています。
        <br />
        そのため、先ほど述べたような「残業をせざるを得ない臨時的な事情があった場合」でも、以下のように残業時間の上限が定められています。なお、この「残業をせざるを得ない臨時的な事情があった場合」は、
        <span>36協定における「特別条項」に該当する</span>
        こともあわせて覚えておきましょう。
        <br />
        <br />
        ①時間外労働は、一年あたり720時間以内にする
        <br />
        ②時間外労働＋休日労働の合計は、1ヶ月あたり100時間未満に収まっている
        <br />
        ③時間外労働＋休日労働の合計は、「2、3、4、5、6ヶ月あたりの平均値」が全て1ヶ月あたり80時間以内に収まっている
        <br />
        ④時間外労働が月45時間を超えることができるのは、一年に6ヶ月を限度とする
        <br />
        <br />
        注意しなければならないのが、②と③の1ヶ月当りの上限時間には、時間外労働に加えて
        <span>「休日労働」</span>
        も含まれるという点です。たとえば、1ヶ月あたりの時間外労働が40時間、休日労働が60時間という場合は、1ヶ月あたりの上限時間となる「45時間以内」をクリアしているものの、前述②の「1ヶ月あたり100時間未満」というルールが守れていないことになるため、法律違反と扱われます。
        <br />
        一方、①の年間あたりの限度時間となる720時間には、休日労働は含まれません。紛らわしいので注意しましょう。
      </p>
      <h1 className="read">まとめ</h1>
      <p>
        法改正により、やむを得ない臨時の事情が生じた場合でも、社員の健康や安全を守るために時間外労働の上限が設けられることになり、違反をした場合は罰則を介して厳しく取り締まりが行われるようになりました。会社側も、安心して社員に働いてもらうために残業時間の
        <span>ルールを熟知</span>
        し、法定労働時間を上回る労働をした社員に対しては適切な形で割増賃金を支払うことを徹底しましょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0027
