import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0027 from '../../../components/molecules/columnDetailInner0027'
import ColumnRelationCards0027 from '../../../components/molecules/columnRelationCards0027'
import UsefulFunctionLayout007 from '../../../components/molecules/usefulFunctionLayout007'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0027 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title:
        'そもそも残業とは？時間外労働との違いや気になる上限時間について解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="残業とは何か？残業の定義や上限時間などを解説します"
        description="残業とは、社員が会社で決められた就労時間を超えて働くことです。具体的な上限時間は、1ヶ月あたり45時間、一年あたり360時間となります。"
        ogUrl="https://kintaicloud.jp/column/details/C0027/"
        ogType="article"
        ogTitle="残業とは何か？残業の定義や上限時間などを解説します"
        ogDescription="残業とは、社員が会社で決められた就労時間を超えて働くことです。具体的な上限時間は、1ヶ月あたり45時間、一年あたり360時間となります。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0027.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0027 />
        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout007 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0027 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0027

export const pageQuery = graphql`
  query C0027 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
